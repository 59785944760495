.dashboradStyle{
    min-height: 60rem;
    padding: 1rem
}

.pieChart{
    width: 60%;
    height: 60%;
   
}


@media only screen and (max-width: 600px) {

    .pieChart{
        width: 90%;
        height: 90%;
      
    }

    .dashboradStyle {
        height: 100%;
        padding: 0
    }
  }