@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

body {
  margin-top: 20px;
  padding-top: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
}


.react-calendar-timeline .rct-header-root {
  background-color: #1976D2 !important;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}   

.rct-sidebar{
  /* height: 6rem !important; */
  text-transform: uppercase !important;
  font-size: 0.9rem !important;
}

/* hide order id from timeline items */
/* .rct-item-content{
  display: none !important;
} */
.rct-dateHeader-primary{
 color: rgba(0, 0, 0, 0) !important;
 font-size: 0 !important;
}
.css-19kzrtu{
  padding:0 !important;
}


/* calender timeline */ 

.react-calendar-timeline .rct-scroll { 
  overflow-x: hidden !important;
  overflow-y: hidden;}

  .MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
  }
